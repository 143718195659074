import React, { useState, useEffect } from "react";
import axios from "axios";
import { Bars } from "react-loader-spinner";
import Sidebar from "../Sidebar";
import CustomBottomNavigation from "../common/BottomNavigationAction";
import { Link } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StorefrontIcon from '@mui/icons-material/Storefront';
import Person from "@mui/icons-material/Person";
import { PersonOutline } from "@material-ui/icons";
import TtyOutlinedIcon from "@mui/icons-material/TtyOutlined";

const Boutiques = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCommune, setSelectedCommune] = useState("");
  const [communes, setCommunes] = useState([]);
  const [adData, setAdData] = useState({
    image_url: "",
    publicite_url: "",
    statut: "",
  });
  const perPage = 30;


  const trackClick = async (annonceId, userId, channel) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}wp-json/click-tracker/v1/track/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          annonce_id: annonceId,
          user_id: userId, // Vous pouvez récupérer l'ID utilisateur depuis l'authentification
          channel: channel, // Le canal: 'call', 'sms', ou 'whatsapp'
        }),
      });
      const result = await response.json();
      //console.log('Click tracked:', result);
    } catch (error) {
      //console.error('Error tracking click:', error);
    }
  };
  

  const fetchAdData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/myplugin/v1/derniere-publicite`;
      const response = await fetch(url);
      const data = await response.json();

      return {
        image_url: data.image_url || "",
        publicite_url: data.publicite_url || "",
        statut: data.statut || "", // Inclure le statut
      };
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données de la publicité:",
        error
      );
      return { image_url: "", publicite_url: "", statut: "" }; // Inclure un statut par défaut
    }
  };

  useEffect(() => {
    const getAdData = async () => {
      const data = await fetchAdData();
      setAdData(data);
    };

    getAdData();
  }, []);


  useEffect(() => {
    const fetchCommunes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/communes`);
        setCommunes(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des communes:", error);
      }
    };

    fetchCommunes();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);

        const url = `${process.env.REACT_APP_API_DOMAIN}wp-json/custom/v1/users-with-annonces/?page=${currentPage}&per_page=${perPage}${selectedCommune ? `&commune=${selectedCommune}` : ""}`;
        //console.log("Fetching URL:", url); // Vérifiez l'URL construite
        
        const response = await axios.get(url);
        //console.log("API Response:", response.data); // Vérifiez la réponse de l'API
        
        setUsers(response.data.data);
        setTotalPages(response.data.total_pages);
      } catch (error) {
        //console.error("Erreur lors de la récupération des utilisateurs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [currentPage, selectedCommune]);

  useEffect(() => {
    setFilteredUsers([]); // Réinitialisez les utilisateurs filtrés
  }, [selectedCommune]);

  useEffect(() => {
    const filterUsers = () => {
      if (searchTerm === "") {
        setFilteredUsers(users);
      } else {
        setFilteredUsers(
          users.filter(user =>
            user.display_name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      }
    };

    filterUsers();
  }, [searchTerm, users]);

  useEffect(() => {
    const fetchData = async () => {
      setTimeout(() => {
        setInitialLoading(false);
      }, 1000);
    };

    fetchData();
  }, []);

  return (
    <>
      {initialLoading ? (
        <div className="loading-overlay">
          <Bars
            height="100"
            width="100"
            color="#e91e63"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="bars-wrapper"
          />
        </div>
      ) : (
        <>
          <div
            className="container"
            style={{
              marginTop: -5,
              overflowY: "auto",
              minHeight: "100vh",
              background: "white",
            }}
          >
            <div
              className="grid-cols-12 md:grid-cols-12 lg:grid-cols-12 lg:pl-10 lg:pr-10"
              style={{ background: "white", marginTop: 100 }}
            >


              {/*
              
              <div className="mb-6 pl-5 pr-5">
                <input
                  type="text"
                  placeholder="Nom du Prestataire..."
                  className="w-full p-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-pink-500"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

               */}

               <div className="mb-6 px-5">
  {/*
  <label htmlFor="commune-select" className="block text-sm font-medium text-gray-700 mb-2">
    Sélectionnez une commune
  </label>
   */}


   {adData.statut.toLowerCase() === "active" && (
              <div
                className="grid lg:pl-10 lg:pr-10"
                style={{ background: "white" }}
              >
                <center>
                  <a
                    href={adData.publicite_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={adData.image_url}
                      alt="Logo publicitaire"
                      style={{
                        width: "90%",
                        marginTop: "2%",
                        marginBottom: "4%",
                      }}
                    />
                  </a>
                </center>
              </div>
            )}



  <select
    id="commune-select"
    className=" p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-pink-500 focus:border-pink-500 transition duration-150 ease-in-out"
    value={selectedCommune}
    onChange={(e) => setSelectedCommune(e.target.value)}
    style={{width:'250px',float:'right'}}
  >
    <option value="">Tous les Prestataires</option>
    {communes.map((commune) => (
      <option key={commune.term_id} value={commune.name}>
        {commune.name}
      </option>
    ))}
  </select>


            

</div>
<br/>



<br/>
  
<br/>

              <div className="grid grid-cols-1 gap-0 md:grid-cols-5 lg:grid-cols-5 pl-5 pr-5">
                {filteredUsers.length !== 0 ?  (
                  filteredUsers.map((user) => (
                    
                    <>

                    <div
                          key={user.id}
                          className="card  flex flex-col gap-5 p-0 bg-white m-4"
                          style={{
                            //boxShadow: "0 2px 8px #00000026",
                            //boxShadow: "0px 1px 10px -2px #828282",
                            borderRadius: ".5rem",
                          }}
                        >
                          
                            <div className="w-full h-64 sm:h-80 md:h-96 lg:h-[200px] overflow-hidden flex justify-center items-center bg-gray-100 relative">
    
                            <img
    src="../../../../blank-profile-picture-973460_960_720.webp"
    alt="Logo"
    className="absolute inset-0 object-cover w-full h-full opacity-2 rounded-lg"
  />


                              {/* Étiquette de la catégorie */}
                              {user.commune && (<>

                                <div
                                className="absolute top-2 right-2 bg-pink-500 text-white py-1 px-2 rounded-lg text-sm font-semibold"
                                style={{ zIndex: 10 }}
                              >
                                {user.commune} 
                              </div>

                              </>)}
                              

                            </div>

                          <h2
                            className="text-lg font-medium pl-5 pr-5 pb-5"
                            style={{ fontWeight: 700, fontSize: "17px", marginTop:-15 }}
                          >
                            <div style={{marginTop:-40,position:'relative', marginBottom:10, fontSize:14, color:'#6c6c6c'}}>{user.user_login}</div>

                            <div
                              className="flex items-center"
                              style={{
                                fontWeight: 400,
                                color: "#6c6c6c",
                                fontSize: 15,
                              }}
                            >
                              
                              
                            </div>

                           {/*
                            <div
                              className="flex items-center"
                              style={{
                                fontWeight: 800,
                                color: "#E91E63",
                                fontSize: 15,
                              }}
                            >
                                
                              <StorefrontIcon/>&nbsp;{user.annonces_count} {user.annonces_count > 1 ? 'annonces' : 'annonce'}

                            </div>
                            */}
                            <Link target="_blank" to={`/boutique/${user.user_login}`}>
                            <div                            
                              className="flex justify-center items-center"
                              style={{
                                fontWeight: 800,                                
                                fontSize: 15,
                                border: "2px solid #e91e64",
                                padding: "4px",
                                borderRadius: 4,
                                marginTop: 5,
                                cursor: "pointer",
                                backgroundColor:'#e91e64',
                                color:'white'
                              }}                              
                            >
                             <StorefrontIcon
                                className="mr-2"
                                style={{
                                  color: "pink",
                                  fontSize: 25,
                                  cursor: "pointer",
                                }}                                
                              />
                           consulter
                            </div>
                            </Link>
                     
                            <div
                            onClick={() => {                              
                              
                                window.open(
                                  `tel:${user.phone}`,
                                  "_blank"
                                );
                            }}
                              className="flex justify-center items-center"
                              style={{
                                fontWeight: 800,
                                
                                fontSize: 15,
                                border: "2px solid #075E54",
                                padding: "4px",
                                borderRadius: 4,
                                marginTop: 5,
                                cursor: "pointer",
                              }}                              
                            >
                             <TtyOutlinedIcon
                                className="mr-2"
                                style={{
                                  color: "rgb(31 191 173)",
                                  fontSize: 25,
                                  cursor: "pointer",
                                }}                                
                              />
                           Appeler
                            </div>
                          </h2>
                        </div>

                    </>
                      
                    
                  ))
                ):(
                  <p className="col-span-full text-center text-gray-500">{filteredUsers.length} Prestataire de Mariage trouvé pour le moment ...</p>
                )}
              </div>
            </div>

            {loading ? (
              <div className="loading-overlay">
                <Bars
                  height="100"
                  width="100"
                  color="#e91e63"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass="bars-wrapper"
                />
              </div>
            ) : (
              <>
                <Sidebar />
                <div className="flex flex-col space-y-4 pt-8" style={{ background: "white" }}>
                  <div className="text-center" style={{ background: "white" }}>
                    <div className="" style={{ marginBottom: "30px" }}>
                      <button
                        className="btn-primary text-white bg-pink-500 rounded-lg hover:shadow-lg disabled:opacity-50"
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        style={{
                          marginRight: "5px",
                          padding: 8,
                          fontSize: 16,
                          fontWeight: "bolder",
                        }}
                      >
                        Précédent
                      </button>
                      <span
                        style={{
                          margin: "0 5px",
                          fontSize: 15,
                          fontWeight: "bolder",
                        }}
                      >
                        Page {currentPage} sur {totalPages}
                      </span>
                      <button
                        className="btn-primary text-white bg-pink-500 rounded-lg hover:shadow-lg disabled:opacity-50"
                        disabled={currentPage === totalPages || totalPages === 0}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        style={{
                          marginLeft: "5px",
                          padding: 8,
                          fontSize: 16,
                          fontWeight: "bolder",
                        }}
                      >
                        Suivant
                      </button>
                    </div>
                  </div>
                </div>
          <br/>
          <br/>
          <br/>
              </>
            )}
          </div>

          <CustomBottomNavigation />
        </>
      )}
    </>
  );
};

export default Boutiques;
