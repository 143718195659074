import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import Joyride from "react-joyride";
import { useNavigate } from "react-router-dom";
import axios from 'axios'; // Assurez-vous d'avoir installé axios

const Alert = () => {
  const [visible, setVisible] = useState(false);
  const [tourActive, setTourActive] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertLinkText, setAlertLinkText] = useState('');
  const [alertLinkUrl, setAlertLinkUrl] = useState('');
  const navigate = useNavigate();

  const closeAlert = () => {
    setVisible(false);
  };

  const startTour = () => {
    closeAlert();
    setTimeout(() => {
      setTourActive(true);
    }, 500);
  };

  useEffect(() => {
    // Remplacez l'URL par celle de votre API si nécessaire
    const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/v1/afficher_notification_alerte`;

    axios
      .get(url)
      .then((response) => {
        const data = response.data;

        if (data && data.afficher_notification_alerte === true) {
          setVisible(true);
          setAlertMessage(data.message || '');
          setAlertLinkText(data.texte_du_lien || '');
          setAlertLinkUrl(data.url_du_lien || '');
        } else {
          setVisible(false);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des notifications :", error);
        setVisible(false);
      });
  }, []);

  return (
    <>
      <Joyride
        steps={[
          {
            target: ".step-1",
            content: "Cliquez ici pour ajouter le service qui vous convient à votre liste de budget, un calcul automatique sera effectué.",
            placement: "bottom",
            disableBeacon: true,
            spotlightPadding: 0,
            scrollTo: true,
          },
          {
            target: ".step-2",
            content: "Cliquez ici pour consulter ou gérer votre liste de Budget.",
            placement: "bottom",
            disableBeacon: true,
            spotlightPadding: 10,
            scrollTo: true,
          },
          // Ajoutez d'autres étapes ici
        ]}
        run={tourActive}
        continuous
        scrollToFirstStep={true}
        showSkipButton
        locale={{
          back: "Retour",
          close: "Fermer",
          last: "Dernier",
          next: "Suivant",
          skip: "Passer",
        }}
        styles={{
          options: {
            zIndex: 10000,
          },
          tooltipContainer: {
            borderRadius: '8px',
            padding: '10px',
            backgroundColor: '#f7f7f7',
            color: '#333',
            fontSize: '14px',
            maxWidth: '300px',
          },
          buttonBack: {
            backgroundColor: '#e0e0e0',
            color: '#333',
          },
          buttonNext: {
            backgroundColor: '#007bff',
            color: '#fff',
          },
        }}
        callback={(data) => {
          if (data.status === "finished" || data.status === "skipped") {
            setTourActive(false);
          }
        }}
      />

      {visible && (
        <div className="fixed bottom-4 right-4 bg-white border border-gray-300 shadow-lg rounded-lg p-4 z-50 flex flex-col space-y-4 max-w-xs">
  <div className="flex flex-col space-y-2">
    <div 
      className="text-sm text-gray-700"
      dangerouslySetInnerHTML={{ __html: alertMessage }}
    />
    {alertLinkText && alertLinkUrl && (
      <a 
        href={alertLinkUrl} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-blue-500 hover:text-blue-700 underline text-sm"
      >
        {alertLinkText}
      </a>
    )}
  </div>
  <button 
    onClick={closeAlert} 
    className="absolute top-0 right-2 text-red-500 hover:text-red-700 bg-white p-1 rounded-full shadow-md"
    style={{marginTop:-10}}
  >
    <FaTimes size={20} />
  </button>
</div>

      )}
    </>
  );
};

export default Alert;
