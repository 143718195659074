import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import CustomBottomNavigation from '../common/BottomNavigationAction';
import { FaEnvelope, FaAt } from 'react-icons/fa';
import { Bars } from 'react-loader-spinner';

const Devis = () => {
  const [sujet, setSujet] = useState('');
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!sujet.trim() || !email.trim()) {
      Swal.fire({
        title: 'Erreur!',
        text: 'Tous les champs doivent être remplis.',
        icon: 'error',
      });
      return;
    }

    setSubmitting(true);

    try {
      const url = `${process.env.REACT_APP_API_ROOT}/soumettre-message`;
      const response = await axios.post(url, {
        sujet: sujet.trim(),
        email: email.trim(),
      });

      if (response.status === 200) {
        Swal.fire({
          title: 'Votre demande a été soumise à tous les Prestataires de Mariage en Côte d\'Ivoire!',
          text: 'Vous aurez un retour dans peu de temps dans votre boîte mail !',
          icon: 'success',
          iconHtml: '<i class="far fa-thumbs-up"></i>',
          customClass: {
            popup: 'text-md',
          },
        });

        setSujet('');
        setEmail('');
      } else {
        Swal.fire({
          title: 'Erreur!',
          text: 'Erreur lors de la soumission du message.',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Erreur lors de la soumission du message:', error);

      Swal.fire({
        title: 'Erreur!',
        text: 'Erreur lors de la soumission du message.',
        icon: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      // Simule un chargement de 2 secondes
      setTimeout(() => {
        setInitialLoading(false); // Arrêter le chargement initial après 2 secondes
      }, 1000);
    };

    fetchData();
  }, []); // Inclure un tableau de dépendances vide pour n'exécuter qu'une fois

  return (
    <>
      {initialLoading ? (
        <div className="loading-overlay">
          <Bars
            height="100"
            width="100"
            color="#e91e63"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          <div style={{ padding: 20, marginTop: '50px' }}>
            <div className="flex items-center justify-center" style={{ borderRadius: 15 }}>
              <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative">
                <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">
                  <FaEnvelope className="inline-block mr-2" />
                  Demande de Devis
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center">
                      <FaAt className="mr-2" />
                      Adresse E-mail
                    </label>
                    <input
                      type="email"
                      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Entrez votre adresse e-mail"
                      disabled={submitting}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Soumettez votre demande à tous les prestataires dans le domaine du mariage
                      en Côte d'Ivoire et recevez des propositions par e-mail, selon votre budget !
                    </label>
                    <textarea
                      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2"
                      value={sujet}
                      onChange={(e) => setSujet(e.target.value)}
                      rows="4"
                      disabled={submitting}
                    />
                  </div>
                  <button
                    className="w-full text-white px-4 py-2 rounded-md transition-all duration-300 relative focus:outline-none focus:ring-"
                    type="submit"
                    disabled={submitting}
                    style={{ background: '#E91E63', fontWeight: 'bolder' }}
                  >
                    {submitting && (
                      <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <div className='w-4 h-4 rounded-full border-4 border-b-0 border-white animate-spin' />
                      </div>
                    )}
                    {submitting ? 'Envoi en cours...' : 'Soumettre Votre Demande'}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <CustomBottomNavigation />
        </>
      )}
    </>
  );
};

export default Devis;
