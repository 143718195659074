import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FaInfoCircle,
  FaCalendarAlt,
  FaProjectDiagra,
  FaImages,
  FaCalendar,
  FaAddressBook,
  FaUserMd,
  FaFilePdf,
  FaPrayingHands,
  FaChurch,
  FaLaptopCode,
  FaCode,
  FaHome,
  FaHouseUser,
  FaPlayCircle,
  FaCalculator,
  FaSearch,
  FaSignInAlt,
  FaCity,
} from "react-icons/fa";
import ContactsIcon from "@material-ui/icons/Contacts"; // Importez l'icône de Material-UI
import HomeIcon from "@material-ui/icons/Home"; // Importez l'icône de Material-UI
import RateReviewIcon from "@material-ui/icons/RateReview";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import CalculateRoundedIcon from "@mui/icons-material/CalculateRounded";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import FindInPageRoundedIcon from "@mui/icons-material/FindInPageRounded";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import LoginIcon from "@mui/icons-material/Login";
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CategoryIcon from '@mui/icons-material/Category';
//https://v4.mui.com/components/material-icons/

import axios from "axios";
import { Android } from "@material-ui/icons";
//import { FaInfo, FaProjectDiagram, FaCalendar, FaFilePdf } from 'react-icons/fa';
//import { MdContacts, MdLocalHospital } from 'react-icons/md';

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#2196f3"); // Couleur par défaut #111827

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    // Faites la requête à votre API pour récupérer la couleur de fond
    const url = `${process.env.REACT_APP_API_ROOT_V1}/background-color`;
    axios
      .get(url)
      .then((response) => {
        // Mettez à jour la couleur de fond dans l'état
        setBackgroundColor(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération de la couleur de fond :", error);
      });
  }, []); // Assurez-vous de passer une dépendance vide pour que cela se produise une seule fois au chargement

  return (
    <>
      <nav className={`fixed w-full z-20 top-0 start-0`} style={{ background: backgroundColor }}>
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
          <Link
            to="/"
            className=""
            style={{ color: "white", fontSize: 22, fontWeight: "bolder" }}
            onClick={closeMenu}
          >
            <CalculateRoundedIcon /> WeddApp
          </Link>
          <button
            onClick={toggleMenu}
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-multi-level"
            aria-expanded={isMenuOpen}
            style={{ backgroundColor: "#f3f4f6" }}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className={`w-full md:flex md:w-auto ${isMenuOpen ? "block" : "hidden"}`}
            id="navbar-multi-level"
          >
            <ul
              className="flex flex-col font-medium p-4 md:p-0 mt-4  rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900"
              style={{ background: backgroundColor }}
            >


<li>
<a
  href="/android.apk"
  className="block mt-4 lg:inline-block lg:mt-0"
  style={{ fontSize: 15, color: "white", fontWeight: "700" }}
  onClick={closeMenu}
  download
>
  <Android /> App Mobile Android
</a>

              </li>
            
              {/*
              <li>
                <Link
                  to="/categories/"
                  className="block mt-4 lg:inline-block lg:mt-0"
                  style={{ fontSize: 15, color: "white", fontWeight: "700" }}
                  onClick={closeMenu}
                >
                  <CategoryIcon />Catégories
                </Link>
              </li>
              <li>
                <Link
                  to="/communes/"
                  className="block mt-4 lg:inline-block lg:mt-0"
                  style={{ fontSize: 15, color: "white", fontWeight: "700" }}
                  onClick={closeMenu}
                >
                  <LocationCityIcon />Communes
                </Link>
              </li>
              
               */}
             {/*
              <li>
                <Link
                  to="/demande-devis/"
                  className="block mt-4 lg:inline-block lg:mt-0"
                  style={{ fontSize: 15, color: "white", fontWeight: "700" }}
                  onClick={closeMenu}
                >
                  <MarkAsUnreadIcon />Devis
                </Link>
              </li>
              */}


              <li>
                <a
                  href="http://inscription.weddapp.ci/"
                  className="block mt-4 lg:inline-block lg:mt-0"
                  style={{ fontSize: 15, color: "white", fontWeight: "700" }}
                  onClick={closeMenu}
                  target="_blank"
                >
                  <NewspaperIcon /> Inscription
                </a>
              </li>


{/*
             
              <li>
                <Link
                  to="https://bowedding.weddapp.ci/connexion"
                  target="_blank"
                  className="block mt-4 lg:inline-block lg:mt-0"
                  style={{ fontSize: 15, color: "white", fontWeight: "700" }}
                  onClick={closeMenu}
                >
                  <LoginIcon /> Connexion
                </Link>
              </li>

              <li>
                <Link
                  to="/creer-son-compte-weddapp/"
                  className="block mt-4 lg:inline-block lg:mt-0"
                  style={{ fontSize: 15, color: "white", fontWeight: "700" }}
                  onClick={closeMenu}
                >
                  <HowToRegIcon /> Inscription
                </Link>
              </li>
 */}
              

            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
