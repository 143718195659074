import React, { useEffect, useState } from 'react';
import axios from 'axios';

const VideoBackground = () => {
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    // Remplacez par l'URL de votre API
    axios.get('https://bowedding.weddapp.ci/wp-json/custom/v1/cover-video')
      .then(response => {
        const data = response.data;
        //console.log(response.data);
        if (response.data) {
          setVideoUrl(response.data);
        } else {
          console.error('Video URL is missing in the response data.');
        }
      })
      .catch(error => console.error('Error fetching video URL:', error));
  }, []);

  return (
       
   
   <div className="video-background-container">
         <div className="dark-overlay"></div>

      {videoUrl && (
        <video className="video-background" autoPlay loop muted>
          <source src={videoUrl} type="video/mp4" />
          PREMIER SIMULATEUR DE BUDGET DE MARIAGE EN CÔTE D'IVOIRE
        </video>
        
      )}
    </div>
  );
};

export default VideoBackground;
