import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import CustomBottomNavigation from '../common/BottomNavigationAction';
import { FaEnvelope, FaUserCheck, FaUserLock, FaPhone, FaUserAlt, FaMobileAlt, FaMailBulk } from 'react-icons/fa';
import { Bars } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom'; // Importez useNavigate

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [communes, setCommunes] = useState([]);
  const [selectedCommune, setSelectedCommune] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const navigate = useNavigate(); // Initialisez useNavigate


  useEffect(() => {
    const fetchCommunes = async () => {
      try {
        const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/communes`;
        const response = await axios.get(url);
        setCommunes(response.data); // Assume que l'API retourne une liste de communes
      } catch (error) {
        Swal.fire({
          title: 'Erreur!',
          text: 'Impossible de récupérer les communes.',
          icon: 'error',
        });
      } finally {
        setInitialLoading(false);
      }
    };

    fetchCommunes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username.trim() || !email.trim() || !phoneNumber.trim() || !password.trim() || !selectedCommune) {
      Swal.fire({
        title: 'Erreur!',
        text: 'Tous les champs doivent être remplis.',
        icon: 'error',
      });
      return;
    }

    setSubmitting(true);

    try {
      const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/myplugin/v1/register/`;
      const response = await axios.post(url, {
        username: username.trim(),
        email: email.trim(),
        phone_number: phoneNumber.trim(),
        password: password.trim(),
        commune: selectedCommune,
      });

      if (response.status === 200 || response.status === 201) {
        Swal.fire({
          title: 'Inscription réussie!',
          text: 'Vous pouvez maintenant vous connecter.',
          icon: 'success',
        });

        // Réinitialisez les champs du formulaire après l'inscription réussie
        setUsername('');
        setEmail('');
        setPhoneNumber('');
        setPassword('');
        setSelectedCommune('');
                // Redirection vers la page de connexion
                //navigate('');
          // Ouvrir la page de connexion dans une nouvelle fenêtre
          //window.open('https://bowedding.weddapp.ci/connexion', '_blank');
          window.location.href = 'https://bowedding.weddapp.ci/connexion';



      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        if (data.code === 'username_exists') {
          Swal.fire({
            title: 'Erreur!',
            text: 'Ce nom d\'utilisateur est déjà pris.',
            icon: 'error',
          });
        } else if (data.code === 'email_exists') {
          Swal.fire({
            title: 'Erreur!',
            text: 'Cet e-mail est déjà utilisé.',
            icon: 'error',
          });
        } else {
          Swal.fire({
            title: 'Erreur!',
            text: 'Erreur lors de l\'inscription.',
            icon: 'error',
          });
        }
      } else {
        Swal.fire({
          title: 'Erreur!',
          text: 'Erreur lors de l\'inscription.',
          icon: 'error',
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {initialLoading ? (
        <div className="loading-overlay">
          <Bars
            height="100"
            width="100"
            color="#e91e63"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <>
          <div style={{ padding: 20, marginTop: '50px', marginBottom: 70 }}>
            <div className="flex items-center justify-center" style={{ borderRadius: 15 }}>
              <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative">
                <h2 className="text-2xl font-bold mb-6 text-center text-gray-800" style={{ marginBottom: 7, marginTop: -10 }}>
                  <FaUserAlt className="inline-block mr-2" />
                  Création de Compte Prestataire Pour Mariage
                </h2>
                <form onSubmit={handleSubmit}>
                  {/* Nom d'utilisateur */}
                  <div className="mb-4" style={{ marginBottom: 7 }}>
                    <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center">
                      <FaUserCheck className="mr-2" />
                      Nom d'utilisateur
                    </label>
                    <input
                      type="text"
                      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Entrez votre nom d'utilisateur"
                      disabled={submitting}
                    />
                  </div>

                  {/* Adresse E-mail */}
                  <div className="mb-4" style={{ marginBottom: 7 }}>
                    <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center">
                      <FaMailBulk className="mr-2" />
                      Adresse E-mail
                    </label>
                    <input
                      type="email"
                      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Entrez votre adresse e-mail"
                      disabled={submitting}
                    />
                  </div>

                  {/* Numéro de téléphone */}
                  <div className="mb-4" style={{ marginBottom: 7 }}>
                    <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center">
                      <FaMobileAlt className="mr-2" />
                      Numéro de téléphone
                    </label>
                    <input
                      type="text"
                      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      placeholder="Entrez votre numéro de téléphone"
                      disabled={submitting}
                    />
                  </div>

                  {/* Commune */}
                  <div className="mb-4" style={{ marginBottom: 7 }}>
                    <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center">
                      <FaUserCheck className="mr-2" />
                      Commune
                    </label>
                    <select
                      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={selectedCommune}
                      onChange={(e) => setSelectedCommune(e.target.value)}
                      disabled={submitting}
                    >
                      <option value="">Sélectionnez votre commune</option>
                      {communes.map((commune) => (
                        <option key={commune.id} value={commune.name}>
                          {commune.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Mot de passe */}
                  <div className="mb-4" style={{ marginBottom: 7 }}>
                    <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center">
                      <FaUserLock className="mr-2" />
                      Mot de passe
                    </label>
                    <input
                      type="password"
                      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Entrez votre mot de passe"
                      disabled={submitting}
                    />
                  </div>

                  {/* Bouton de soumission */}
                  <button
                    className="w-full text-white px-4 py-2 rounded-md transition-all duration-300 relative focus:outline-none"
                    type="submit"
                    disabled={submitting}
                    style={{ background: '#E91E63', fontWeight: 'bolder' }}
                  >
                    {submitting && (
                      <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <div className='w-4 h-4 rounded-full border-4 border-b-0 border-white animate-spin' />
                      </div>
                    )}
                    {submitting ? 'Envoi en cours...' : 'S\'inscrire'}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <CustomBottomNavigation />
        </>
      )}
    </>
  );
};

export default Register;
