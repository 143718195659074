import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import he from 'he';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faCalendar, faEye } from "@fortawesome/free-solid-svg-icons";
import { FacebookShareButton, WhatsappShareButton, FacebookIcon, WhatsappIcon } from 'react-share';
import CustomBottomNavigation from '../../common/BottomNavigationAction';
import { Bars } from 'react-loader-spinner';
import CategoryIcon from '@mui/icons-material/Category';
import NewspaperRounded from '@mui/icons-material/NewspaperRounded';
const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [postDates, setPostDates] = useState({});
  const [postCategories, setPostCategories] = useState({});
  const [postVisits, setPostVisits] = useState({});
  const [loading, setLoading] = useState(true); // État de chargement
  const [annonces, setAnnonces] = useState([]);
  const [adData, setAdData] = useState({ image_url: '', publicite_url: '', statut: '' });
  const perPage = 9;



  const fetchPostVisits = async (slug) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/post-visits/${slug}`;
      const response = await axios.get(url);
      const visits = response.data;
      setPostVisits((prevVisits) => ({
        ...prevVisits,
        [slug]: visits,
      }));
    } catch (error) {
      console.error(`Error fetching post visits for post ${slug}:`, error);
    }
  };

  const fetchAdData = async () => {
    try {
        const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/myplugin/v1/derniere-publicite`;
        const response = await fetch(url);
        const data = await response.json();

        return {
            image_url: data.image_url || '',
            publicite_url: data.publicite_url || '',
            statut: data.statut || '' // Inclure le statut
        };
    } catch (error) {
        console.error('Erreur lors de la récupération des données de la publicité:', error);
        return { image_url: '', publicite_url: '', statut: '' }; // Inclure un statut par défaut
    }
};


  useEffect(() => {
    const getAdData = async () => {
      const data = await fetchAdData();
      setAdData(data);
    };

    getAdData();
  }, []);

 

  const fetchPosts = async (page) => {
    setLoading(true); // Début du chargement
    try {
      const url = `${process.env.REACT_APP_API_ROOT}/posts?per_page=${perPage}&page=${page}`;
      const response = await axios.get(url);
      const fetchedPosts = response.data;
      const { headers } = response;
      setTotalPages(Number(headers['x-wp-totalpages']));

      const postsWithVisits = await Promise.all(
        fetchedPosts.map(async (post) => {
          if (post.slug) {
            //await fetchPostVisits(post.slug);
          }
          return post;
        })
      );

      const fetchCategory = async (categoryId) => {
        try {
          const categoryUrl = `${process.env.REACT_APP_API_ROOT}/categories/${categoryId}`;
          const categoryResponse = await axios.get(categoryUrl);
          return categoryResponse.data.name;
        } catch (error) {
          console.error(`Error fetching category details for category ID ${categoryId}:`, error);
          return '';
        }
      };

      const postsWithCategories = await Promise.all(
        fetchedPosts.map(async (post) => {
          if (post.categories) {
            const categoryNames = await Promise.all(
              post.categories.map(async (categoryId) => fetchCategory(categoryId))
            );
            setPostCategories((prevCategories) => ({
              ...prevCategories,
              [post.id]: categoryNames,
            }));
          }
          return post;
        })
      );

      setPosts(postsWithCategories);

      const postsWithImages = await Promise.all(
        fetchedPosts.map(async (post) => {
          if (post.featured_media) {
            const imageUrl = await fetchFeaturedImage(post.featured_media);
            return { ...post, imageUrl };
          }
          return post;
        })
      );

      setPosts(postsWithImages);

      const dates = await Promise.all(
        fetchedPosts.map(async (post) => {
          return { id: post.id, date: post.date };
        })
      );

      const datesObject = dates.reduce((acc, date) => {
        acc[date.id] = date.date;
        return acc;
      }, {});

      setPostDates(datesObject);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false); // Fin du chargement
    }
  };

  const fetchFeaturedImage = async (mediaId) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT}/media/${mediaId}`;
      const response = await axios.get(url);
      return response.data.source_url;
    } catch (error) {
      console.error(`Error fetching featured image for media ID ${mediaId}:`, error);
      return '';
    }
  };

  const truncateDescription = (description) => {
    if (description.length > 60) {
      return description.substring(0, 60) + '...';
    }
    return description;
  };

  const CustomWhatsappShareButton = ({ posts }) => {
    const message = posts.length > 0 ? he.decode(posts[0].title.rendered) : '';
    const postUrl = posts.length > 0 ? `${process.env.REACT_APP_API_DOMAIN}posts/${posts[0].id}` : '';
    const defaultMessage = "bonjour COACH LEWI";
    const whatsappUrl = `https://api.whatsapp.com/send?phone=22565110441&text=${encodeURIComponent(defaultMessage)}`;
  
    return (
      <button
        className='share-button text-white bg-green-500'
        disabled={posts.length === 0}
        style={{ float: 'right', marginRight: '5px', padding: 8, fontSize: 16 }}
        onClick={() => window.location.href = whatsappUrl}
      >
        <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: '5px' }} className='share-icon' />
      </button>
    );
  };
  
  useEffect(() => {
    fetchPosts(currentPage);
  }, [currentPage]);

  return (
    <>
      {loading ? (
        <div className="loading-overlay">
          <Bars
            height="100"
            width="100"
            color="#e91e63"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="bars-wrapper"
          />
        </div>
      ) : (
        <>
        <div className="containe mx-auto lg:p-20 " style={{background:'white', width:'100%', height:'100%', marginTop:'30px'}}>



  <div className="flex flex-col space-y-4">
    <div className=" p-4 rounded">
      
    <div className="containe mx-auto my-auto p-1" style={{ marginTop: '-20px', background:'white' }}>
      
    <div className="mobile-space block lg:hidden">
  <br />
  <br />
  <br />
</div>

<br/>


        


{/**<h1 className='lg:w-5/6 xl:w-4/5 2xl:w-3/4  py-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5 text-2xl font-bold mb-5' style={{ fontSize: 20, marginTop:'-50px', marginLeft:'0px' }}><CategoryIcon style={{color:'#e91e63'}}/> </h1> */}

{adData.statut.toLowerCase() === 'active' && (
            
                <center style={{marginTop:-50, marginBottom:70}}>
                    <a href={adData.publicite_url} target="_blank" rel="noopener noreferrer">
                        <img
                            src={adData.image_url}
                            alt="Logo publicitaire"
                            style={{ width: "90%", marginTop: "0%", marginBottom: "0%" }}
                        />
                    </a>
                </center>
        )}


    <div className="flex items-center" style={{marginTop:'-40px'}}>
      <NewspaperRounded style={{ color: '#e91e63', fontSize: 28 }} />
      <span style={{ marginLeft: 8, fontWeight:'bolder', fontSize:19}} className="">Derniers articles</span>
    </div>
    <br/>
    <br/>


        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-5 p-0 ' style={{ marginTop: '-20px'}}>
          {Object.keys(posts).length ? (
            posts.map((post, index) => (

              <div>                  

              <Link to={`/${post.slug}`} style={{ position: 'relative', display: 'block' }}>
  {postCategories[post.id] && postCategories[post.id].map((category, index) => (
    <Link 
      key={index} 
      //to={`/articles/${post.categories}`} // Ou le lien de la catégorie si vous avez une page dédiée      incrementArticleVisits(post.slug)
      style={{
        position: 'absolute', 
        top: '10px', 
        right: '10px',
      }}
    >
      <span style={{ 
        fontSize: 14,         
        color: 'white', 
        fontWeight: '700', 
        borderRadius:4,
        width: 'auto', 
        padding: '10px'
      }}>
         <CategoryIcon style={{color:'#e91e63'}}/> {category} 
      </span>
    </Link>
  ))}
  <img
    src={post.featured_src || 'https://via.placeholder.com/500'}
    alt={post.title.rendered}
    style={{ width: '100%', display: 'block' }}
  />
</Link>

<>
                    <div key={post.id} className='card shadow-lg rounded-lg flex flex-col gap-5 p-5 bg-white m-4  bg-white' style={{marginTop:-40,position:'relative',boxShadow:'0px 1px 10px -2px #828282'}}>
                    
                <Link to={`/${post.slug}`}>
                  
                  <h2 className="text-lg font-medium" style={{ fontWeight: '700', fontSize: 16}}>
                    {(he.decode(post.title.rendered))} 
                  </h2>          
                  </Link>        
                                    

                
                    </div>
                    </>
                </div>


            ))
          ) : (

            <>
            <br />

        <div className="loading-overlay">
          <Bars
            height="100"
            width="100"
            color="#e91e63"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>

            </>
          )}
        </div>
      </div>
      <div className="containe mx-auto">
  <div className="flex flex-col space-y-4">
    <div className="">
    <center>
        <div className='' style={{ marginBottom: '30px' }}>
          <button
            className='btn-primary text-white bg-blue-500 rounded-lg hover:shadow-lg disabled:opacity-50'
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
            style={{ marginRight: '5px', padding: 8, fontSize: 16, fontWeight:'bolder', background: '#e91e63' }}
          >
            Précédent
          </button>
          <span style={{ margin: '0 5px', fontSize: 15, fontWeight:'bolder' }}>{currentPage} sur {totalPages}</span>
          <button
            className='btn-primary text-white bg-blue-500 rounded-lg hover:shadow-lg disabled:opacity-50'
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{ marginLeft: '5px', padding: 8, fontSize: 16, fontWeight:'bolder', background: '#e91e63' }}
          >
            Suivant
          </button>
        </div>
      </center>
    </div>
    
  </div>
</div>
    </div>
    
  </div>
</div>
        </>
      )}      
      <CustomBottomNavigation />
    </>
  );
};

export default Posts;
