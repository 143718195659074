import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Swal from 'sweetalert2';

const EmailModal = ({ isOpen, onClose, onEmailSubmit }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = async () => {
    if (email) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/wp-json/newsletter/v1/add`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email })
        });
        if (response.ok) {
          Swal.fire({
            title: 'Félicitations',
            text: 'Les boutons ont été déverouillés avec succès, Téléchargez à présent les fichiers et n\'hésitez pas à revenir sur notre simulateur pour d\'autres calculs !',
            icon: 'success'
          }).then(() => {
            onEmailSubmit(email); // Déverrouille les boutons après le succès
            onClose(); // Ferme la modal après le succès
          });
        } else {
          Swal.fire({
            title: 'Erreur',
            text: 'Erreur lors de la soumission de l\'email.',
            icon: 'error'
          });
        }
      } catch (error) {
        Swal.fire({
          title: 'Erreur',
          text: 'Erreur lors de la soumission de l\'email.',
          icon: 'error'
        });
      }
    } else {
      Swal.fire({
        title: 'Erreur',
        text: 'Veuillez entrer une adresse e-mail valide.',
        icon: 'error'
      });
    }
  };

  if (!isOpen) return null;

  return (
    <center>
      <div className="modal-overlay">
      <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mx-4 sm:mx-0">
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
        <h2 className="text-lg font-semibold mb-4">Veuillez entrer votre adresse e-mail pour déverrouiller les boutons de Téléchargement</h2>
        <input
          type="email"
          className="w-full border border-gray-300 rounded-md p-2 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Votre e-mail"
        />
        <div className="flex justify-between">
        <button
            onClick={onClose}
            className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg shadow-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Annuler
          </button>
          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Envoyer
          </button>

        </div>
      </div>
    </div>
      </div>
    </center>
  );
};

export default EmailModal;
