import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list'; // Importez le plugin de vue liste
import axios from 'axios';
import '@fullcalendar/core/locales-all';
import '@fullcalendar/core/locales/fr'; // Importez le fichier de localisation français
import Footer from '../Footer';
import CustomBottomNavigation from '../common/BottomNavigationAction';

const MyFullCalendar = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
        const url = `${process.env.REACT_APP_API_ROOT_V1}/events`;
        const response = await axios.get(url);
      console.log('Données récupérées avec succès :', response.data);
      setEvents(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des événements :', error);
    }
  };

  return (
    <>
      <div className='mt-20'>
      <FullCalendar
        plugins={[dayGridPlugin, listPlugin]} // Activez le plugin de vue liste
        initialView="dayGridMonth"
        events={events.map(event => ({
          title: event.title,
          start: event.start,
          end: event.end,
        }))}
        locale="fr"
        views={{
          list: { // Configurez la vue liste
            buttonText: 'Liste', // Texte du bouton pour basculer vers la vue liste
          },
        }}
        headerToolbar={{
          start: 'dayGridMonth,listMonth', // Boutons pour basculer entre les vues
          center: 'title',
          end: 'today prev,next',
        }}
        defaultView="list"//dayGridMonth
        height="auto" // Ajustez la hauteur automatiquement en fonction du contenu
        windowResizeDelay={0} // Rafraîchit immédiatement lorsqu'il y a un redimensionnement de la fenêtre
        eventDisplay="block" // Affiche les événements comme des blocs pour une meilleure lisibilité
      />
      {/*<Footer/>*/}
        <br/>
        <br/>
        <br/>
        <br/>

      <CustomBottomNavigation/>
    </div>

    </>
  );
};

export default MyFullCalendar;
