import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Bars } from 'react-loader-spinner';
import UndoSharpIcon from '@mui/icons-material/UndoSharp';
import Modal from 'react-modal';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Importez les styles de Lightbox
import CustomBottomNavigation from '../../common/BottomNavigationAction';
import Swal from 'sweetalert2';
import { WhatsappIcon } from 'react-share';
import { FaEnvelope, FaHeart, FaMobile, FaPhone, FaWhatsapp } from 'react-icons/fa';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonIcon from '@mui/icons-material/Person';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CollectionsIcon from '@mui/icons-material/Collections';
import MapIcon from '@mui/icons-material/Map';
import PaymentsIcon from '@mui/icons-material/Payments';
import Sidebar from "../../Sidebar";
import { Helmet } from 'react-helmet'; // Importer Helmet
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import SmsIcon from '@mui/icons-material/Sms';

Modal.setAppElement('#root'); // Ajoutez cette ligne pour éviter les avertissements de React Modal

const Details = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [wishlist, setWishlist] = useState([]);
  const navigate = useNavigate();



  const toggleWishlist = () => {
    if (!post) return;

    const isInWishlist = wishlist.some((item) => item.id === post.id);

    if (isInWishlist) {
      Swal.fire({
        title: "Vraiment ?",
        text: "Voulez-vous vraiment retirer cette annonce de votre panier ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, retirer !",
        cancelButtonText: "Non conserver",
        cancelButtonColor: "#333",
      }).then((result) => {
        if (result.isConfirmed) {
          const updatedWishlist = wishlist.filter((item) => item.id !== post.id);

          // Sauvegarder la liste de souhaits mise à jour dans le localStorage
          localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));

          // Afficher une alerte pour confirmer que l'élément a été retiré
          Swal.fire(
            "Dommage !",
            "L'annonce a été supprimée de votre panier.",
            "success"
          );

          setWishlist(updatedWishlist);
        }
      });
    } else {
      const updatedWishlist = [...wishlist, post];

      // Sauvegarder la liste de souhaits dans le localStorage
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));

      // Afficher une alerte pour confirmer que l'élément a été ajouté
      Swal.fire({
        title: "Parfait !",
        text: `L'annonce "${post.title}" a été ajoutée à la liste des éléments qui constituent votre budget !`,
        icon: "success",
        confirmButtonText: "OK",
      });

      setWishlist(updatedWishlist);
    }
  };

  // Charger les éléments de la liste de souhaits depuis le localStorage
  useEffect(() => {
    const savedWishlist = localStorage.getItem("wishlist");
    if (savedWishlist) {
      setWishlist(JSON.parse(savedWishlist));
    }
  }, []);



  const trackClick = async (annonceId, userId, channel) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}wp-json/click-tracker/v1/track/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          annonce_id: annonceId,
          user_id: userId, // Vous pouvez récupérer l'ID utilisateur depuis l'authentification
          channel: channel, // Le canal: 'call', 'sms', ou 'whatsapp'
        }),
      });
      const result = await response.json();
      //console.log('Click tracked:', result);
    } catch (error) {
      console.error('Error tracking click:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postUrl = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/post?slug=${slug}`;
        const response = await axios.get(postUrl);
        if (response.data) {
          setPost(response.data);
        } else {
          console.error('No post data found');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    document.body.classList.add('hidden-video');

    return () => {
      document.body.classList.remove('hidden-video');
    };
  }, [slug]);

  const handleClick = () => {
    //navigate("/");
    navigate(-1);
  };

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goToPrevious = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : post.acf_fields.galerie.length - 1));
  };

  const goToNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex < post.acf_fields.galerie.length - 1 ? prevIndex + 1 : 0));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const nom = formData.get('nom');
    const prenom = formData.get('prenom');
    const contact = formData.get('contact');
    const message = formData.get('message');

    if (!nom || !prenom || !contact || !message) {
      await Swal.fire({
        title: 'Champs requis',
        text: 'Veuillez remplir tous les champs du formulaire avant de soumettre.',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK',
      });
      return;
    }

    const { value: confirm } = await Swal.fire({
      title: 'Confirmer l\'envoi',
      text: `Voulez-vous envoyer ce message au numéro WhatsApp de l'annonceur (${post.acf_fields.telephone}) ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, envoyer !',
      cancelButtonText: 'Annuler',
    });

    if (confirm) {
      const formattedMessage = `Nom: ${nom}\nPrénom: ${prenom}\nContact: ${contact}\nMessage: ${message}\n\nEnvoyé depuis www.weddapp.ci`;
      
      // Créez l'URL WhatsApp avec le message
      const whatsappUrl = `https://api.whatsapp.com/send?phone=${post.acf_fields.telephone}&text=${encodeURIComponent(formattedMessage)}`;

      // Ouvrir l'URL dans un nouvel onglet
      window.open(whatsappUrl, '_blank');
      
      // Afficher un message de confirmation
      Swal.fire(
        'Envoyé !',
        'Votre message a été envoyé avec succès.',
        'success'
      );
    }
  };

  if (loading) {
    return (
      <div className="loading-overlay">
        <Bars height="100" width="100" color="#e91e63" ariaLabel="bars-loading" />
      </div>
    );
  }

  if (!post) {
    return <div className="text-center mt-5">No post data available</div>;
  }

  return (
    <>
    <Helmet>
        <title>WEDDAPP - {post.title}</title>
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.acf_fields?.description || 'Description par défaut'} />
        <meta property="og:image" content={post.featured_image} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>


    <Sidebar/>         
      <div className="containe mx-auto mt-20 px-4 sm:px-10 lg:px-16 lg:pl-20 lg:pr-20" style={{ background:'white'}} >
        <button onClick={handleClick} className="flex items-center mb-5 pl-5" style={{color:'#e91e64', fontWeight:'bolder'}}>
          <UndoSharpIcon className="mr-2" style={{ fontSize: 28, fontWeight: 'bolder', color:'#e91e64' }} />
          Revenir
        </button>
         
        
        <div className="flex flex-col lg:flex-row lg:space-x-4" style={{ marginBottom: 50 }}>
          <div className="bg-white p-4 mb-4 lg:mb-0 lg:w-3/4" style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px', borderRadius: '0.5rem' }}>
          <div className="flex flex-col sm:flex-row items-center mb-5">
        <h2 className="text-2xl font-bold flex items-center mb-0 sm:mb-0">
          <AddBusinessIcon style={{ fontSize: 30 }} className='mr-2' />
          {post.title}
        </h2>
        <button 
          onClick={toggleWishlist} 
          className={`flex items-center mt-4 sm:mt-0 sm:ml-4 px-3 py-1 border rounded-lg mb-1`} 
          style={{ color: wishlist.some((item) => item.id === post.id) ? '#E91E63' : 'grey', fontSize: '17px' }}
        >
          <FaHeart className={`mr-2 transition-transform duration-300 ${wishlist.some((item) => item.id === post.id) ? '' : 'animate-heartbeat'}`} />
          {wishlist.some((item) => item.id === post.id) ? 'Retirer des souhaits' : 'Ajouter à ma liste'}
        </button>
      </div>

            <img src={post.featured_image} alt={post.title} className="mb-4 w-full h-auto" />
            <hr style={{ marginTop: 40, marginBottom: 20 }} />
            <div
              className="text-base mb-4"
              dangerouslySetInnerHTML={{ __html: post.content }}
            />                        
            <hr style={{ marginTop: 10, marginBottom: 10 }} />
            <p className="text-base" style={{color:'rgb(9 111 160)'}}>
  


<div className='items-center flex'>
<a
                            onClick={() => {
                              //console.log(post);
                              trackClick(post.id, post.author_id, 'call'); // Enregistrement du clic                              
                                window.open(
                                  `tel:${post.acf_fields.telephone}`,
                                  "_blank"
                                );
                            }}
                            className="bg-blue-500 text-white py-1 px-4 rounded-lg flex items-center space-x-2 mr-2"
                            style={{ fontSize: 15, cursor: "pointer", backgroundColor:'#1bb5bc', width:110 }}
                          >
                            <FontAwesomeIcon icon={faPhone} />
                            <span style={{ fontSize: 15 }}>Appeler</span>
                          </a>
<a
                                onClick={(e) => {
                                  trackClick(post.id, post.author_id, 'sms'); // Enregistrement du clic
                                  e.preventDefault(); // Empêche le comportement par défaut                                  
                                  Swal.fire({
                                    title: "Attention",
                                    text: "N’effectuez aucun paiement avant d’avoir l’assurance que c’est le bon prestataire !",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonText: "Envoyer SMS",
                                    cancelButtonText: "Annuler",
                                    reverseButtons: true,  // Inverse l'ordre des boutons
                                    customClass: {
                                      cancelButton: "swal2-cancel-button",
                                    },
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      // Si l'utilisateur confirme, le SMS est envoyé
                                      const message = `Bonjour Madame/Monsieur ${
                                        post.author_name
                                      }, j'ai trouvé votre service sur WEDDAPP.CI, lien : ${`${process.env.REACT_APP_SITE_DOMAINE}details/${post.slug}`}, je suis intéressé.`;

                                      window.open(
                                        `sms:${
                                          post.acf_fields.telephone
                                        }?body=${encodeURIComponent(message)}`,
                                        "_blank"
                                      );
                                    }
                                  });
                                }}
                            className="bg-blue-500 text-white py-1 px-4 rounded-lg flex items-center space-x-2 mr-2"
                            style={{ fontSize: 15, cursor: "pointer", backgroundColor:'#1bb5bc', width:110 }}
                          >
                            <SmsIcon />
                            <span style={{ fontSize: 15 }}>sms</span>
                          </a>

                         

</div>



</p>

            <hr style={{ marginTop: 10, marginBottom: 10 }} />
            <p className="text-base"><strong><PaymentsIcon className='mr-2'/>  Coût:</strong> {post.acf_fields.cout}</p>
            <hr style={{ marginTop: 10, marginBottom: 10 }} />
            <p className="text-base"><strong><MapIcon className='mr-2'/>  Situation géographique:</strong> {post.acf_fields.situation_geographique}</p>
            <hr style={{ marginTop: 10, marginBottom: 10 }} />
            {post.acf_fields.galerie && post.acf_fields.galerie.length > 0 && (
              <div>
                <h2 className="text-xl font-semibold mb-2"><CollectionsIcon className='mr-2'/> Galerie Photo</h2>
                <div className="grid grid-cols-2 gap-4">
                  {post.acf_fields.galerie.map((image, index) => (
                    <div key={image.id} className="p-2 rounded cursor-pointer" onClick={() => openModal(index)}>
                      <img src={image.url} alt={image.name} className="w-full h-auto rounded" />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
                    
          <div className="bg-white p-4 lg:w-1/4" style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px', borderRadius: '0.5rem' }}>
            <h2 className="text-xl font-bold mb-4">Ecrire à {post.author_name}</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center" htmlFor="nom">
                  <PersonIcon className='mr-2'/> VOTRE NOM                  
                </label>
                <input
                  type="text"
                  id="nom"
                  name="nom"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center" htmlFor="prenom">
                  VOTRE PRÉNOM
                </label>
                <input
                  type="text"
                  id="prenom"
                  name="prenom"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center" htmlFor="contact">
                  <SmartphoneIcon className='mr-2'/> VOTRE CONTACT
                </label>
                <input
                  type="number"
                  id="contact"
                  name="contact"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2 flex items-center" htmlFor="message">
                 <MailOutlineIcon className='mr-2'/>  VOTRE MESSAGE
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-green-600 text-white px-4 py-2 rounded-lg shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 flex items-center"
                style={{fontWeight:'bolder'}}
              >
                <FaWhatsapp style={{fontWeight:'bolder'}} className='mr-2'/> Envoyer un Whatsapp
              </button>
            </form>
          </div>
        </div>
        <br/>
        <br/>
        <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal">
          <button onClick={closeModal} className="absolute top-2 right-2 text-xl">
            &times;
          </button>
          {post.acf_fields.galerie && post.acf_fields.galerie.length > 0 && (
            <Lightbox
              mainSrc={post.acf_fields.galerie[currentImageIndex].url}
              nextSrc={post.acf_fields.galerie[(currentImageIndex + 1) % post.acf_fields.galerie.length].url}
              prevSrc={post.acf_fields.galerie[(currentImageIndex + post.acf_fields.galerie.length - 1) % post.acf_fields.galerie.length].url}
              onCloseRequest={closeModal}
              onMovePrevRequest={goToPrevious}
              onMoveNextRequest={goToNext}
              imageTitle={post.acf_fields.galerie[currentImageIndex].name}
            />
          )}
        </Modal>
      </div>
      <CustomBottomNavigation />
    </>
  );
};

export default Details;
