import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import he from 'he';
import CustomBottomNavigation from "../../common/BottomNavigationAction";

const SingleById = () => {
  const { id } = useParams();
  const [post, setPost] = useState({});
  const [postDates, setPostDates] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postUrl = `${process.env.REACT_APP_API_ROOT}/posts/${id}`;

        const postResponse = await axios.get(postUrl);
        const postData = postResponse.data;
        if (!postData) {
          throw new Error("No post data found");
        }
        setPost(postData);

        const postDateUrl = `${process.env.REACT_APP_API_ROOT}/posts/${postData.id}?_fields=date`;
        const postDateResponse = await axios.get(postDateUrl);
        if (!postDateResponse.data) {
          throw new Error("No date data found");
        }
        setPostDates({ ...postDates, [postData.id]: postDateResponse.data.date });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <div className='col-span-3 w-4 h-4 absolute left-1/2 top-1/2 rounded-full border-4 border-b-0 border-blue-500 animate-spin' />;
  }

  return (
    <>
      {Object.keys(post).length ? (
        <div className="container mx-auto p-10 bg-white mt-20" style={{ marginBottom: 100 }}>
          <div>
            <h1 className="text-3xl font-bold mb-3">
              <img
                src={post.featured_src}
                style={{ width: 35, height: 35, float: 'left', borderRadius: '100%' }}
                alt="Article Thumbnail"
              />&nbsp;
              {he.decode(post.title.rendered)}
            </h1>
          </div>
          <div className="mb-5">
            <img src={post.featured_src} alt={post.title.rendered} className="w-full h-auto rounded-lg" />
          </div>
          <div>
            <p className="text-gray-500 text-sm" style={{ fontWeight: 'bolder', marginBottom: 10, fontSize: 16 }}>
              Date de publication : {new Date(postDates[post.id]).toLocaleDateString()}
            </p>
          </div>
          <div className="text-lg leading-relaxed" dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
        </div>
      ) : (
        <div className='col-span-3 w-4 h-4 absolute left-1/2 top-1/2 rounded-full border-4 border-b-0 border-blue-500 animate-spin' />
      )}
      <CustomBottomNavigation />
    </>
  );
};

export default SingleById;
