import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../Footer";
import CustomBottomNavigation from "../common/BottomNavigationAction";



const AboutPage = () => {
  const [aboutContent, setAboutContent] = useState("");


  useEffect(() => {
    const fetchAboutPage = async () => {
      try {
        // Remplacez l'URL par l'URL réelle de votre site WordPress et l'ID du post par celui de votre page A PROPOS
        const url = `${process.env.REACT_APP_API_ROOT}/pages/1965`;
        const response = await axios.get(url);
        
        // Assurez-vous que la propriété 'content' existe dans la réponse
        if (response.data && response.data.content) {
          setAboutContent(response.data.content.rendered);
        }
      } catch (error) {
        console.error("Error fetching About page content:", error);
      }
    };

    fetchAboutPage();
  }, []); // Le tableau vide signifie que cet effet ne s'exécute qu'une fois après le montage du composant

  return (
<>
<div className="container mx-auto my-auto mb-0 p-5 mt-20">
      {/*<h1 className='text-2xl font-bold'>À propos de nous</h1> */}
      <div dangerouslySetInnerHTML={{ __html: aboutContent }} />
    </div>
    <div style={{height:'50px'}}></div>
    <CustomBottomNavigation/>
    {/*<Footer/>*/}
</>
  );
};

export default AboutPage;
