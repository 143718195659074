import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faUser, faPhone } from "@fortawesome/free-solid-svg-icons";
import Footer from "../Footer";
import CustomBottomNavigation from "../common/BottomNavigationAction";
import Sidebar from "../Sidebar";
import Skeleton from "react-loading-skeleton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import TtyOutlinedIcon from "@mui/icons-material/TtyOutlined";
import DialpadRoundedIcon from "@mui/icons-material/DialpadRounded";
import SmsIcon from "@mui/icons-material/Sms";
import {
  FaBeer,
  FaCalendar,
  FaCalendarTimes,
  FaCheckDouble,
  FaCity,
  FaCog,
  FaCoins,
  FaHeart,
  FaImage,
  FaMobile,
  FaPhone,
  FaPhoneAlt,
  FaSearch,
  FaTimes,
  FaTimesCircle,
} from "react-icons/fa";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import "./search.css";
import { Bars } from "react-loader-spinner";
import Select from "react-select";
import { Link } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import Swal from "sweetalert2";
import CategoryCarousel from "../CategoryCarousel";
import Alert from "../Alert";
import Categories from "../Categories";
import Results from "./Results";
import { useNavigate } from "react-router-dom";

const Annonces = () => {
  const [annonces, setAnnonces] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [services, setServices] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const perPage = 16;

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [adData, setAdData] = useState({
    image_url: "",
    publicite_url: "",
    statut: "",
  });
  const [visitCount, setVisitCount] = useState(""); // État pour le nombre de visites
  const [imageUrl, setImageUrl] = useState("");
  const [totalAnnonces, setTotalAnnonces] = useState(0);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  // Nouvel état pour le coût
  const [cost, setCost] = useState("");
  const [budget, setBudget] = useState("");
  const [wishlist, setWishlist] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriess, setCategoriess] = useState([]);
  const [communess, setCommuness] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const [placeholder, setPlaceholder] = useState("");
  // Fonction pour encoder le message dans l'URL WhatsApp
  const encodeMessage = (message) => {
    return encodeURIComponent(message);
  };

  useEffect(() => {
    const targetText = "De quoi avez-vous besoin ?";
    let index = 0;
    const intervalTime = 300; // Vitesse de l'animation

    const animatePlaceholder = () => {
      setPlaceholder((prev) => {
        if (index >= targetText.length) {
          index = 0;
        }
        const newText = targetText.slice(0, index + 1);
        index++;
        return newText;
      });
    };

    const interval = setInterval(animatePlaceholder, intervalTime);

    return () => clearInterval(interval); // Nettoyage de l'intervalle lors du démontage du composant
  }, []);

  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const handleSearchh = (e) => {
    e.preventDefault();
    // Redirige vers la page des résultats avec la requête en tant que paramètre
    navigate(`/results?query=${encodeURIComponent(query)}`);
  };

  const trackClick = async (annonceId, userId, channel) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}wp-json/click-tracker/v1/track/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            annonce_id: annonceId,
            user_id: userId, // Vous pouvez récupérer l'ID utilisateur depuis l'authentification
            channel: channel, // Le canal: 'call', 'sms', ou 'whatsapp'
          }),
        }
      );
      const result = await response.json();
      //console.log('Click tracked:', result);
    } catch (error) {
      //console.error('Error tracking click:', error);
    }
  };

  // Fonction pour créer le lien WhatsApp
  const createWhatsAppLink = (phone, message) => {
    return `https://api.whatsapp.com/send/?phone=${phone}&text=${encodeMessage(
      message
    )}`;
    //https://api.whatsapp.com/send/?phone=%2B2250565110441&text=
  };

  const domaine = process.env.REACT_APP_SITE_DOMAINE;

  useEffect(() => {
    const targetText = `Selectionnez votre besoin`;
    let index = 0;
    const intervalTime = 300; // Vitesse de l'animation

    const animatePlaceholder = () => {
      setPlaceholder((prev) => {
        if (index >= targetText.length) {
          index = 0;
        }
        const newText = targetText.slice(0, index + 1);
        index++;
        return newText;
      });
    };

    const interval = setInterval(animatePlaceholder, intervalTime);

    return () => clearInterval(interval); // Nettoyage de l'intervalle lors du démontage du composant
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const toggleWishlist = (annonce) => {
    const isInWishlist = wishlist.some((item) => item.id === annonce.id);

    if (isInWishlist) {
      Swal.fire({
        title: "Vraiment ?",
        text: "Voulez-vous vraiment retirer cette annonce de votre panier ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, retirer !",
        cancelButtonText: "Non conserver",
        cancelButtonColor: "#333",
      }).then((result) => {
        if (result.isConfirmed) {
          const updatedWishlist = wishlist.filter(
            (item) => item.id !== annonce.id
          );

          // Sauvegarder la liste de souhaits mise à jour dans le localStorage
          localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));

          // Afficher une alerte pour confirmer que l'élément a été retiré
          Swal.fire(
            "Dommage !",
            "L'annonce a été supprimée de votre panier.",
            "success"
          );

          setWishlist(updatedWishlist);
        }
      });
    } else {
      const updatedWishlist = [...wishlist, annonce];

      // Sauvegarder la liste de souhaits dans le localStorage
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));

      // Afficher une alerte pour confirmer que l'élément a été ajouté
      Swal.fire({
        title: "Parfait !",
        text: `l'annonce "${annonce.title}" a été ajoutée à la liste des éléments qui constituent votre budget !`,
        icon: "success",
        confirmButtonText: "OK",
      });

      setWishlist(updatedWishlist);
    }
  };

  // Charger les éléments de la liste de souhaits depuis le localStorage
  useEffect(() => {
    const savedWishlist = localStorage.getItem("wishlist");
    if (savedWishlist) {
      setWishlist(JSON.parse(savedWishlist));
    }
  }, []);

  const formatNumber = (value) => {
    if (!value) return "";
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const parseNumber = (value) => {
    return value.replace(/\s+/g, "");
  };

  const handleCostChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = parseNumber(inputValue);
    const formattedValue = formatNumber(numericValue);
    setCost(numericValue); // Set the numeric value for internal use
    setBudget(formattedValue); // Update display value if needed
  };

  const handleKeyDown = (e) => {
    const invalidChars = /[a-zA-Z]/;
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Home",
      "End",
    ];

    if (allowedKeys.includes(e.key)) {
      return;
    }

    if (invalidChars.test(e.key)) {
      e.preventDefault();
    }
  };

  const truncateText = (text, length = 20) => {
    if (text.length <= length) return text;
    return `${text.substring(0, length)}...`;
  };

  useEffect(() => {
    // Remplacez l'URL par celle de votre API si nécessaire
    const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/v1/afficher_notification_alerte`;
    axios
      .get(url)
      .then((response) => {
        // Assurez-vous que la réponse contient le champ 'afficher_notification_alerte'
        if (response.data && response.data.afficher_notification_alerte) {
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des notifications :",
          error
        );
        setShowAlert(false);
      });
  }, []); // Le tableau vide signifie que l'effet s'exécute une seule fois après le premier rendu

  useEffect(() => {
    // Fonction pour obtenir l'URL de l'image depuis l'API
    const fetchImageUrl = async () => {
      try {
        const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/fond-image/`;
        const response = await fetch(url);
        const data = await response.json();

        // Debug: afficher les données obtenues
        //console.log("API Response:", data);

        // Vérifiez si l'URL est retournée correctement
        if (data) {
          setImageUrl(data);
          //console.log("VOILA");
          //console.log(data);
        } else {
          console.error("URL de l'image non trouvée");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'image:", error);
      }
    };

    const fetchTotalAnnonces = async () => {
      try {
        const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/total-annonces/`;
        const response = await axios.get(url);
        setTotalAnnonces(response.data.total_annonces);
      } catch (error) {
        console.error("Erreur lors de la récupération des annonces:", error);
      }
    };

    fetchTotalAnnonces();
    fetchImageUrl();
  }, []);

  const fetchAdData = async () => {
    try {
      const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/myplugin/v1/derniere-publicite`;
      const response = await fetch(url);
      const data = await response.json();

      return {
        image_url: data.image_url || "",
        publicite_url: data.publicite_url || "",
        statut: data.statut || "", // Inclure le statut
      };
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données de la publicité:",
        error
      );
      return { image_url: "", publicite_url: "", statut: "" }; // Inclure un statut par défaut
    }
  };

  useEffect(() => {
    const getAdData = async () => {
      const data = await fetchAdData();
      setAdData(data);
    };

    getAdData();
  }, []);

  useEffect(() => {
    fetchAnnonces(currentPage); // Charger les annonces à chaque changement de page
  }, [currentPage]);

  const fetchAnnonces = async (page = 1, category = null, commune = null) => {
    try {
      setLoading(true);

      //console.log("Selected Service:", selectedService);
      //console.log("Selected Location:", selectedLocation);
      //console.log("Cost:", cost);

      // Construisez les paramètres de la requête
      const serviceQuery =
        selectedService && selectedService.value
          ? `&categorie_annonce=${selectedService.value}`
          : "";
      const locationQuery =
        selectedLocation && selectedLocation.value
          ? `&commune=${selectedLocation.value}`
          : "";
      const costQuery = cost ? `&cout=${encodeURIComponent(cost)}` : "";

      // Nouveau paramètre pour la catégorie
      const categoryQuery = category
        ? `&categorie_annonce=${encodeURIComponent(category)}`
        : "";

      // Nouveau paramètre pour la commune
      const communeQuery = commune
        ? `&commune=${encodeURIComponent(commune)}`
        : "";

      // Construire l'URL avec les paramètres de recherche
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/annonces?page=${page}&per_page=${perPage}${serviceQuery}${locationQuery}${costQuery}${categoryQuery}${communeQuery}`;

      //console.log("Fetching URL:", url);

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Erreur lors de la récupération des annonces");
      }
      const data = await response.json();

      //console.log("Response Data:", data);

      setAnnonces(data.data || []);
      setTotalPages(data.pagination ? data.pagination.total_pages : 1);
      setTotalResults(data.pagination ? data.pagination.total_items : 0);
    } catch (error) {
      console.error("Error fetching annonces:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnnonces(); // Charger les annonces au premier rendu
  }, []);

  useEffect(() => {
    fetchServices();
    fetchLocations();
  }, []);

  const fetchServices = async () => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/categories-annonce`;
      const response = await axios.get(url);
      setServices(
        response.data.map((categorie_annonce) => ({
          value: categorie_annonce.name,
          label: categorie_annonce.name,
        }))
      );
      setInitialLoading(false);
    } catch (error) {
      console.error("Error fetching services:", error);
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/categories/`;
    axios
      .get(url)
      .then((response) => {
        setCategoriess(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des catégories:", error);
      });
  }, []);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/bycommunes/`;
    axios
      .get(url)
      .then((response) => {
        setCommuness(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des communes:", error);
      });
  }, []);

  const fetchLocations = async () => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/communes`;
      const response = await axios.get(url);
      setLocations(
        response.data.map((location) => ({
          value: location.name,
          label: location.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  // Cette fonction est appelée lorsque l'utilisateur clique sur "Chercher"
  const handleSearchNew = (e) => {
    e.preventDefault();

    // Collectez les critères de recherche
    const searchParams = new URLSearchParams();
    if (selectedService)
      searchParams.append("categorie_annonce", selectedService.value); // Utilisez .value si `selectedService` est un objet
    if (selectedLocation)
      searchParams.append("commune", selectedLocation.value); // Idem pour `selectedLocation`
    if (cost) searchParams.append("cost", cost);

    // Ajoutez d'autres critères selon vos besoins
    // Redirigez vers la page des résultats avec les paramètres de recherche
    navigate(`/results?${searchParams.toString()}`);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setCurrentPage(1); // Reset to first page on new search
    fetchAnnonces(1); // Fetch annonces for the first page with new filters
  };

  const handleSearchhh = (e) => {
    e.preventDefault();
    if (query.trim()) {
      navigate(`/results?query=${encodeURIComponent(query)}`);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Recherche vide",
        text: "Veuillez entrer un terme de recherche.",
      });
    }
  };

  useEffect(() => {
    // Fonction pour enregistrer la visite
    const trackVisit = async () => {
      try {
        const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/track-visit/`;
        await fetch(url, {
          method: "POST",
        });
      } catch (error) {
        console.error("Erreur lors du comptage de la visite:", error);
      }
    };

    const fetchVisitCount = async () => {
      try {
        const url2 = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/visit-count`;
        const response = await fetch(url2);
        const data = await response.json();
        setVisitCount(data.visit_count);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du nombre de visites:",
          error
        );
      }
    };

    //trackVisit();
    //fetchVisitCount();

    const fetchData = async () => {
      await trackVisit();
      await fetchVisitCount();
      setInitialLoading(false); // Arrêter le chargement initial
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Remplacez l'URL ci-dessous par l'URL de votre API
    const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/categories/`;
    axios
      .get(url)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des catégories:", error);
      });
  }, []);

  useEffect(() => {
    fetchCategories(); // Récupère les catégories à l'initialisation du composant
  }, []);

  const fetchCategories = async () => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/categories-annonce`;
      const response = await axios.get(url);
      setCategories(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des catégories:", error);
    }
  };

  const fetchAnnoncesByCategory = async (categoryId) => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/annonces?categorie_id=${categoryId}`;
      const response = await axios.get(url);
      setAnnonces(response.data.data || []);
    } catch (error) {
      console.error("Erreur lors de la récupération des annonces:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryClick = (category) => {
    fetchAnnonces(1, category, null); // Appel de la fonction fetchAnnonces avec la catégorie sélectionnée
    setSelectedLocation("");
    setSelectedService("");
  };

  const handleCommuneClick = (commune) => {
    //console.log(commune)
    fetchAnnonces(1, null, commune); // Appel de la fonction fetchAnnonces avec la catégorie sélectionnée
    setSelectedLocation("");
    setSelectedService("");
  };

  // Affiche les 4 premières catégories si showAllCategories est faux, sinon affiche toutes les catégories

  const [showAllCategories, setShowAllCategories] = useState(false);
  // Filtrer les catégories à afficher en fonction de l'état showAllCategories
  const displayedCategories = showAllCategories
    ? categoriess
    : categoriess.slice(0, 4);

  const handleToggleCategories = () => {
    setShowAllCategories(!showAllCategories);
  };

  const [showAllCommunes, setShowAllCommunes] = useState(false);
  // Filtrer les catégories à afficher en fonction de l'état showAllCategories
  const displayedCommunes = showAllCommunes ? communess : communess.slice(0, 4);

  const handleToggleCommunes = () => {
    setShowAllCommunes(!showAllCommunes);
  };

  return (
    <>
      <div
        className="container flex justify-center items-center min-h-screen  text-white"
        style={{ overflowY: "auto" }}
      >
        {/* Logo et barre de recherche */}
        <div
          className="flex flex-col items-center w-full max-w-5xl px-0 space-y-2"
          style={{ marginTop: -0 }}
        >
          {/* Logo */}
          {/* Barre de recherche */}
          <p className="mb-2 font-extrabold text-3xl md:text-5xl text-center ">
            www.weddapp.ci
          </p>

          <div className="w-full bg-white bg-opacity-30 p-4 shadow-lg rounded-full ">
            <form
              className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-6 p-0"
              onSubmit={handleSearchNew}
            >
              <Link to="/" className="flex justify-center items-center">
                <img
                  src="/WEEDDAPP_LOGO64.png"
                  alt="Logo"
                  className="w-[100px] sm:w-[250px]" // Utilisation de classes Tailwind
                />
              </Link>

              <Select
                options={services}
                value={selectedService}
                onChange={setSelectedService}
                placeholder="Type de prestataire"
                isClearable={true}
                className="w-full " // Largeur fixe pour les écrans plus larges
                styles={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: "white", // #333333 Fond du champ principal
                    color: "white",
                    borderColor: "#d7d7d7",
                    fontSize: "1rem",
                    fontWeight: 700,
                    //padding: "0.8rem",
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: "rgb(43, 43, 43)", // Fond du menu déroulant
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "transparent"
                      : state.isFocused
                      ? "rgb(43, 43, 43)"
                      : "rgb(43, 43, 43)",
                    color: state.isSelected ? "white" : "white", // Texte noir pour l'option sélectionnée, blanc sinon
                    padding: 10,
                    fontWeight: state.isSelected ? "bold" : "normal", // Mettre en gras l'option sélectionnée pour accentuer le changement
                    cursor: "pointer",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: "#333333", // Couleur du texte dans le champ principal lorsqu'un élément est sélectionné
                  }),
                }}
              />
              <Select
                options={locations}
                value={selectedLocation}
                onChange={setSelectedLocation}
                placeholder="Où ?"
                isClearable={true}
                className="w-full" // Largeur fixe pour les écrans plus larges
                styles={{
                  control: (base) => ({
                    ...base,
                    backgroundColor: "white", // #333333 Fond du champ principal
                    color: "white",
                    borderColor: "#d7d7d7",
                    fontSize: "1rem",
                    fontWeight: 700,
                    //padding: "0.8rem",
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: "rgb(43, 43, 43)", // Fond du menu déroulant
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "transparent"
                      : state.isFocused
                      ? "rgb(43, 43, 43)"
                      : "rgb(43, 43, 43)",
                    color: state.isSelected ? "white" : "white", // Texte noir pour l'option sélectionnée, blanc sinon
                    padding: 10,
                    fontWeight: state.isSelected ? "bold" : "normal", // Mettre en gras l'option sélectionnée pour accentuer le changement
                    cursor: "pointer",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: "#333333", // Couleur du texte dans le champ principal lorsqu'un élément est sélectionné
                  }),
                }}
              />
              <button
                type="submit"
                className="w-full sm:w-auto px-5 py-2 rounded-full bg-[#E91E63] text-white flex items-center justify-center font-bold text-xl hover:bg-[#c2185b] transition-all"
                style={{
                  fontSize: "1rem",
                }}
              >
                <FaSearch className="mr-2" />
                <span>Chercher</span>
              </button>
            </form>
          </div>
          <p className="text-center mb-2 font-extrabold text-sm">
            {totalAnnonces} {totalAnnonces > 1 ? "prestataires" : "prestataire"}
            <br />
            {totalAnnonces > 1 ? "enregistrés" : "enregistré"} à ce jour
            <br />
            <div style={{ marginTop: 20 }}>
              <a
                target="_blank"
                href="https://inscription.weddapp.ci/"
                style={{ color: "#E91E63" }}
              >
                Cliquez pour s'inscire
              </a>
            </div>
          </p>
        </div>
      </div>
      <CustomBottomNavigation />
    </>
  );
};

export default Annonces;
