import './App.css';
import Pages from './components/pages/pages';


function App() {
  return (
    <>
      <Pages/>
    </>
  );
}

export default App;
