import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Bars } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!usernameOrEmail.trim() || !password.trim()) {
      Swal.fire({
        title: 'Erreur!',
        text: 'Tous les champs doivent être remplis.',
        icon: 'error',
      });
      return;
    }

    setSubmitting(true);

    try {
      const url = `${process.env.REACT_APP_API_DOMAIN}wp-json/jwt-auth/v1/token`;
      const response = await axios.post(url, {
        username: usernameOrEmail.trim(),
        password: password.trim(),
      });

      console.log('Response:', response.data);

      if (response.status === 200) {
        const token = response.data.token;
        console.log('Token:', token);

        localStorage.setItem('token', token);

        Swal.fire({
          title: 'Connexion réussie!',
          text: 'Vous êtes maintenant connecté.',
          icon: 'success',
        });

        // Rediriger vers le backoffice WordPress
        window.location.href = `${process.env.REACT_APP_API_DOMAIN}wp-admin/?jwt=${token}`;
      }
    } catch (error) {
      console.error('Erreur lors de la connexion', error);
      Swal.fire({
        title: 'Erreur!',
        text: 'Nom d’utilisateur ou mot de passe incorrect.',
        icon: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div style={{ padding: 20, marginTop: '50px', marginBottom: 70 }}>
      <div className="flex items-center justify-center" style={{ borderRadius: 15 }}>
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative">
          <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">
            Connexion à votre compte
          </h2>
          <form onSubmit={handleLogin}>
            <input
              type="text"
              placeholder="Nom d'utilisateur ou E-mail"
              value={usernameOrEmail}
              onChange={(e) => setUsernameOrEmail(e.target.value)}
              disabled={submitting}
              className="mb-4 p-2 border border-gray-300 rounded w-full"
            />
            <input
              type="password"
              placeholder="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={submitting}
              className="mb-4 p-2 border border-gray-300 rounded w-full"
            />
            <button
              type="submit"
              disabled={submitting}
              className="bg-pink-500 text-white p-2 rounded w-full"
            >
              {submitting ? 'Connexion...' : 'Se connecter'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
