import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./resultatPage.css"; // Assurez-vous d'importer le fichier CSS
import { FaFacebook, FaSearch, FaUber, FaUser } from "react-icons/fa";
import "./search.css";
import { Bars } from "react-loader-spinner";
import Select from "react-select";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import CategoryCarousel from "../CategoryCarousel";
import CustomBottomNavigation from "../common/BottomNavigationAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import Navbar from "../common/Navbar";

const ResultatPage = () => {
  const [annonces, setAnnonces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({
    categorie_annonce: "",
    location: "",
    cost: "",
  });
  const location = useLocation();
  const [imageUrl, setImageUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalAnnonces, setTotalAnnonces] = useState(0);

  const [selectedService, setSelectedService] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [services, setServices] = useState([]);
  const [locations, setLocations] = useState([]);

  const [initialLoading, setInitialLoading] = useState(true);
  const perPage = 10;

  const [adData, setAdData] = useState({
    image_url: "",
    publicite_url: "",
    statut: "",
  });
  const [totalResults, setTotalResults] = useState("");
  // Nouvel état pour le coût
  const [cost, setCost] = useState("");
  const [budget, setBudget] = useState("");
  const [wishlist, setWishlist] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriess, setCategoriess] = useState([]);
  const [communess, setCommuness] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const [placeholder, setPlaceholder] = useState("");
  // Fonction pour encoder le message dans l'URL WhatsApp
  const encodeMessage = (message) => {
    return encodeURIComponent(message);
  };

  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const domaine = process.env.REACT_APP_SITE_DOMAINE;

  useEffect(() => {
    // Fonction pour obtenir l'URL de l'image depuis l'API
    const fetchImageUrl = async () => {
      try {
        const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/fond-image/`;
        const response = await fetch(url);
        const data = await response.json();

        // Debug: afficher les données obtenues
        //console.log("API Response:", data);

        // Vérifiez si l'URL est retournée correctement
        if (data) {
          setImageUrl(data);
          //console.log("VOILA");
          //console.log(data);
        } else {
          console.error("URL de l'image non trouvée");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'image:", error);
      }
    };

    const fetchTotalAnnonces = async () => {
      try {
        const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/total-annonces/`;
        const response = await axios.get(url);
        setTotalAnnonces(response.data.total_annonces);
      } catch (error) {
        console.error("Erreur lors de la récupération des annonces:", error);
      }
    };

    fetchTotalAnnonces();
    fetchImageUrl();
  }, []);

  useEffect(() => {
    fetchServices();
    fetchLocations();

    // Initialiser les valeurs des champs de recherche à partir des paramètres de l'URL
    const searchParams = new URLSearchParams(location.search);
    const initialCategorie = searchParams.get("categorie_annonce");
    const initialCommune = searchParams.get("commune");
    const initialCost = searchParams.get("cost");

    // Mettre à jour les états avec les valeurs des paramètres d'URL
    setSelectedService(
      initialCategorie
        ? { value: initialCategorie, label: initialCategorie }
        : null
    );
    setSelectedLocation(
      initialCommune ? { value: initialCommune, label: initialCommune } : null
    );
    setCost(initialCost || "");
  }, []);

  const fetchServices = async () => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/categories-annonce`;
      const response = await axios.get(url);
      setServices(
        response.data.map((categorie_annonce) => ({
          value: categorie_annonce.name,
          label: categorie_annonce.name,
        }))
      );
      setInitialLoading(false);
    } catch (error) {
      console.error("Error fetching categorie_annonce:", error);
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/categories/`;
    axios
      .get(url)
      .then((response) => {
        setCategoriess(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des catégories:", error);
      });
  }, []);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/bycommunes/`;
    axios
      .get(url)
      .then((response) => {
        setCommuness(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des communes:", error);
      });
  }, []);

  const fetchLocations = async () => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/communes`;
      const response = await axios.get(url);
      setLocations(
        response.data.map((location) => ({
          value: location.name,
          label: location.name,
        }))
      );
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  // Cette fonction est appelée lorsque l'utilisateur clique sur "Chercher"
  const handleSearchNew = (e) => {
    e.preventDefault();

    // Collectez les critères de recherche
    const searchParams = new URLSearchParams();
    if (selectedService)
      searchParams.append("categorie_annonce", selectedService.value); // Utilisez .value si `selectedService` est un objet
    if (selectedLocation)
      searchParams.append("commune", selectedLocation.value); // Idem pour `selectedLocation`
    if (cost) searchParams.append("cost", cost);

    //console.log(selectedService);
    //console.log(selectedService.value);
    //console.log(selectedLocation);
    //console.log(selectedLocation.value);

    // Logique de pagination et autres paramètres
    const page = 1; // Ou la page actuelle
    const perPage = 10; // Nombre d'éléments par page
    const serviceQuery = selectedService
      ? `&categorie_annonce=${selectedService.value}`
      : "";
    const locationQuery = selectedLocation
      ? `&commune=${selectedLocation.value}`
      : "";
    const costQuery = cost ? `&cost=${cost}` : "";
    const categoryQuery = ""; // Ajoutez la logique pour la catégorie si nécessaire
    const communeQuery = ""; // Ajoutez la logique pour la commune si nécessaire

    //const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/annonces?page=${page}&per_page=${perPage}${serviceQuery}${locationQuery}${costQuery}${categoryQuery}${communeQuery}`;
    const url = `${
      process.env.REACT_APP_API_ROOT_CUSTOM_V1
    }/annonces?page=${page}&per_page=${perPage}&categorie_annonce=${
      selectedService ? selectedService.value : ""
    }&commune=${selectedLocation ? selectedLocation.value : ""}`;

    // Affichage de l'URL pour débogage
    //console.log("API URL:", url);

    // Redirigez vers la page des résultats avec les paramètres de recherche
    navigate(`/results?${searchParams.toString()}`);
  };

  useEffect(() => {
    // Remplacez l'URL ci-dessous par l'URL de votre API
    const url = `${process.env.REACT_APP_API_DOMAIN}/wp-json/custom/v1/categories/`;
    axios
      .get(url)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des catégories:", error);
      });
  }, []);

  useEffect(() => {
    fetchCategories(); // Récupère les catégories à l'initialisation du composant
  }, []);

  const fetchCategories = async () => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/categories-annonce`;
      const response = await axios.get(url);
      setCategories(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des catégories:", error);
    }
  };

  // Affiche les 4 premières catégories si showAllCategories est faux, sinon affiche toutes les catégories

  const [showAllCategories, setShowAllCategories] = useState(false);
  // Filtrer les catégories à afficher en fonction de l'état showAllCategories
  const displayedCategories = showAllCategories
    ? categoriess
    : categoriess.slice(0, 4);

  const handleToggleCategories = () => {
    setShowAllCategories(!showAllCategories);
  };

  const [showAllCommunes, setShowAllCommunes] = useState(false);
  // Filtrer les catégories à afficher en fonction de l'état showAllCategories
  const displayedCommunes = showAllCommunes ? communess : communess.slice(0, 4);

  const handleToggleCommunes = () => {
    setShowAllCommunes(!showAllCommunes);
  };

  useEffect(() => {
    const fetchResults = async (page = 1) => {
      const queryParams = new URLSearchParams(location.search);
      const categorie_annonce = queryParams.get("categorie_annonce");
      const locationParam = queryParams.get("commune");
      const cost = queryParams.get("cost");

      //console.log(service);
      //console.log(locationParam);

      setSearchParams({ categorie_annonce, commune: locationParam, cost });

      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/annonces`,
          {
            params: {
              categorie_annonce: categorie_annonce,
              commune: locationParam,
              cost: cost,
              page, // Passer le numéro de page
              per_page: perPage, // Passer le nombre d'éléments par page
            },
          }
        );
        //console.log('Response:', response.data); // Ajoutez cette ligne pour déboguer

        setAnnonces(response.data.data || []);

        // Fonction pour créer le lien WhatsApp avec un message
        const createWhatsAppLink = (phone, message) => {
          return `https://api.whatsapp.com/send/?phone=${phone}&text=${encodeURIComponent(
            message
          )}`;
        };

        // Message personnalisé pour le prestataire
        const message = `Bonjour Madame/Monsieur , j'ai trouvé votre service sur https://weddapp.ci, je suis intéressé par vos services`;
        // Lien WhatsApp

        const totalResults = response.data.pagination.total_items; // Utiliser le bon chemin
        setTotalResults(totalResults);

        // Calculer le total des pages
        setTotalPages(Math.ceil(totalResults / perPage)); // Utilisez totalResults ici
        //console.log(response.data.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des résultats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [location]);

  const handleSearch = (e) => {
    e.preventDefault();
    const queryString = new URLSearchParams(searchParams).toString();
    navigate(`/results?${queryString}`); // Utilisez navigate à la place de history.push
  };

  if (loading) {
    <div className="loading-overlay">
      <Bars
        height="100"
        width="100"
        color="#e91e63"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="bars-wrapper"
      />
    </div>;
  }

  // Fonction pour changer de page
  const handlePageChange = async (newPage) => {
    setCurrentPage(newPage); // Met à jour la page actuelle
    const queryParams = new URLSearchParams(location.search);
    const categorie_annonce = queryParams.get("categorie_annonce");
    const locationParam = queryParams.get("commune");
    const cost = queryParams.get("cost");

    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_ROOT_CUSTOM_V1}/annonces`,
        {
          params: {
            categorie_annonce: categorie_annonce,
            commune: locationParam,
            cost: cost,
            page: newPage, // Passer le numéro de page
            per_page: perPage, // Passer le nombre d'éléments par page
          },
        }
      );

      setAnnonces(response.data.data || []);
      const totalResults = response.data.pagination.total_items;
      setTotalResults(totalResults);
      setTotalPages(Math.ceil(totalResults / perPage));
    } catch (error) {
      console.error("Erreur lors de la récupération des résultats:", error);
    } finally {
      setLoading(false);
    }
  };

  // Déplacez cette fonction en dehors de votre useEffect
  const createWhatsAppLink = (phone, message) => {
    return `https://api.whatsapp.com/send/?phone=${phone}&text=${encodeURIComponent(
      message
    )}`;
  };

  // Message personnalisé pour le prestataire
  const message = `Bonjour Madame/Monsieur, j'ai trouvé votre service sur https://weddapp.ci/, je suis intéressé par vos services`;

  return (
    <>
     <Navbar />
      <div className="container">
        {/* Barre de recherche */}

        <div
          className="overflow-y-auto   result-container max-h-[180vh]"
          style={{ background: "#f8fafb" }}
        >
          {loading ? (
            <div className="loading-overlay">
              <Bars
                height="100"
                width="100"
                color="#e91e63"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars-wrapper"
              />
            </div>
          ) : (
            <>
              {/* Première ligne */}
              <div
                className="flex justify-center items-center py-4"
                style={{ background: "transparent" }}
              >
                {/* Zone avec image de fond et barre de recherche */}
                <div
                  className="relative w-full custom-heigh bg-cover bg-center flex justify-center items-center"                  
style={{marginTop:70}}
                >

                <div
                      className="rounded-lg p-4 shadow-lg w-full max-w-5xl text-center rounded-full "
                      style={{ marginTop: 0 }}
                      style={{ background: "#2b2b2b", margin:10, boxShadow:'rgb(130, 130, 130) 0px 1px 10px -2px' }}
                    >
                      <div className="w-full">
                        <form
                          className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-6 p-0"
                          onSubmit={handleSearchNew}
                        >
                          <Link
                            to="/"
                            className="flex justify-center items-center"
                          >
                            <img
  src="/WEEDDAPP_LOGO64.png"
  alt="Logo"
  className="w-[100px] sm:w-[250px]" // Utilisation de classes Tailwind
/>

                          </Link>

                          <Select
                            options={services}
                            value={selectedService}
                            onChange={setSelectedService}
                            required
                            placeholder="Type de prestataire"
                            isClearable={true}
                            className="w-full " // Largeur fixe pour les écrans plus larges
                            styles={{
                              control: (base) => ({
                                ...base,
                                backgroundColor: "white", // #333333 Fond du champ principal
                                color: "white",
                                borderColor: "#d7d7d7",
                                fontSize: "1rem",
                                fontWeight:700
                                //padding: "0.8rem",
                              }),
                              menu: (base) => ({
                                ...base,
                                backgroundColor: "#555555", // Fond du menu déroulant
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected
                                  ? "#555555"
                                  : state.isFocused
                                  ? "#555555"
                                  : "#555555",
                                color: state.isSelected ? "white" : "white", // Texte noir pour l'option sélectionnée, blanc sinon
                                padding: 10,
                                fontWeight: state.isSelected
                                  ? "bold"
                                  : "normal", // Mettre en gras l'option sélectionnée pour accentuer le changement
                                cursor: "pointer",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                color: "#333333", // Couleur du texte dans le champ principal lorsqu'un élément est sélectionné
                              }),
                            }}
                          />
                          <Select
                            options={locations}
                            value={selectedLocation}
                            onChange={setSelectedLocation}
                            required
                            placeholder="Où ?"
                            isClearable={true}
                            className="w-full" // Largeur fixe pour les écrans plus larges
                            styles={{
                              control: (base) => ({
                                ...base,
                                backgroundColor: "white", // #333333 Fond du champ principal
                                color: "white",
                                borderColor: "#d7d7d7",
                                fontSize: "1rem",
                                fontWeight:700
                                //padding: "0.8rem",
                              }),
                              menu: (base) => ({
                                ...base,
                                backgroundColor: "#555555", // Fond du menu déroulant
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected
                                  ? "#555555"
                                  : state.isFocused
                                  ? "#555555"
                                  : "#555555",
                                color: state.isSelected ? "white" : "white", // Texte noir pour l'option sélectionnée, blanc sinon
                                padding: 10,
                                fontWeight: state.isSelected
                                  ? "bold"
                                  : "normal", // Mettre en gras l'option sélectionnée pour accentuer le changement
                                cursor: "pointer",
                              }),
                              singleValue: (base) => ({
                                ...base,
                                color: "#333333", // Couleur du texte dans le champ principal lorsqu'un élément est sélectionné
                              }),
                            }}
                          />
                          <button
                            type="submit"
                            className="w-full sm:w-auto px-5 py-2 rounded-full bg-[#E91E63] text-white flex items-center justify-center font-bold text-xl hover:bg-[#c2185b] transition-all"
                            style={{
                              fontSize: "1rem",
                            }}
                          >
                            <FaSearch className="mr-2" />
                            <span>Chercher</span>
                          </button>
                        </form>
                      </div>
                    </div>
                </div>
              </div>
              <div style={{ padding: 2 }}>
                {annonces.length === 0 ? (
                  <div style={{ marginLeft: 30 }}>Aucun résultat trouvé.</div>
                ) : (
                  <>
                    <div
                      style={{
                        color: "#333333",
                        fontSize: "1.1rem",
                        fontWeight: 700,
                        marginLeft: 20,
                        paddingTop: 5,
                        marginBottom: 5,
                      }}
                    >
                      {totalResults} contacts trouvés sur un total de {totalAnnonces}, correspondants à votre
                      recherche !
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 p-4">
  {annonces.map((annonce) => (
    <div
      key={annonce.id}
      className="bg-white rounded-lg shadow-lg p-4 flex transition-transform transform hover:scale-10"
      style={{boxShadow:'rgb(130, 130, 130) 0px 1px 10px -2px'}}
    >
      {/* Image carrée de l'annonce */}
      <img
        src={annonce.featured_image || "https://via.placeholder.com/150"}
        alt={annonce.title}
        className="w-24 h-24 object-cover rounded-md mr-4 shadow-md"
      />

      {/* Contenu de l'annonce */}
      <div className="flex flex-col justify-between">
        <h2 className="text-lg font-bold text-gray-800 mb-1 hover:text-blue-600 transition-colors">
        <a
  href={annonce.acf_fields.adresse_email}
  target="_blank"
  rel="noopener noreferrer"
  className="hover:underline ml-1 flex items-center"
  style={{ color: 'blue', textDecoration: 'underline', fontSize: 16 }}
>
  <FaFacebook style={{ marginRight: '4px' }} /> {annonce.title}
</a>

        </h2>
        <p className="text-gray-600 text-sm mb-2 line-clamp-3">
                {/* Affichez les catégories */}
                {annonce.categories.join(", ")}
        </p>

        <div className="flex flex-col text-sm mt-0">
          <a
            href={`tel:${annonce.acf_fields.telephone}`}


            onClick={() => {                            
                            //trackClick(annonce.id, annonce.author_id, 'call'); // Enregistrement du clic
                            //console.log('Click tracked for call'); // Affiche un message sans exécuter à nouveau la fonction
                                window.open(
                                  `tel:${annonce.acf_fields.telephone}`,
                                  "_blank"
                                );
                            }}

            className="text-blue-500 hover:text-blue-700 flex items-center transition-colors"
            style={{color:'rgb(233 30 100)', fontWeight:700}}
          >
            <FontAwesomeIcon icon={faPhone} className="mr-1" />
            {annonce.acf_fields.telephone}
          </a>

          

          

          {/* Bouton WhatsApp */}
          <a
            href={createWhatsAppLink(
              annonce.acf_fields.telephone,
              "Bonjour, je suis intéressé(e) par vos services trouvés sur https://weddapp.ci/"
            )}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center mt-4 bg-green-500 text-white p-2 rounded-lg hover:bg-green-600 transition-colors text-sm shadow hover:shadow-lg"
            style={{fontWeight:700}}
          >
            <FontAwesomeIcon icon={faWhatsapp} className="mr-2" />
            Ecrire sur WhatsApp
          </a>
        </div>
      </div>
    </div>
  ))}
</div>

                  </>
                )}

                {/* Pagination */}
                {/*
              <p style={{ color: "#70757a", marginTop: 10 }}>
                Total des pages: {totalPages}
              </p>
               */}

                <div className="pagination">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`page-button ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    style={{ fontSize: "14px" }}
                  >
                    Précédent
                  </button>

                  {/*
        {Array.from({ length: totalPages }, (_, index) => (
          <button 
            key={index + 1} 
            onClick={() => handlePageChange(index + 1)} 
            className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
          >
            {index + 1}
          </button>
        ))} 

         */}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={`page-button ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                    style={{ fontSize: "14px" }}
                  >
                    Suivant
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <CustomBottomNavigation />
    </>
  );
};

export default ResultatPage;
