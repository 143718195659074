import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import he from 'he';
import CustomBottomNavigation from "../../common/BottomNavigationAction";
import { FaHome } from "react-icons/fa";
import { Bars } from "react-loader-spinner";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import UndoSharpIcon from '@mui/icons-material/UndoSharp';
import Sidebar from "../../Sidebar";
const SingleBySlug = () => {
  const { slug } = useParams();
  const [post, setPost] = useState({});
  const [postDates, setPostDates] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1); // Retourne à la page précédente
  };


  useEffect(() => {

    const fetchData = async () => {
      try {
        const postUrl = `${process.env.REACT_APP_API_ROOT}/posts?slug=${slug}`;

        const postResponse = await axios.get(postUrl);
        const postData = postResponse.data[0]; // Prendre le premier article trouvé
        if (!postData) {
          throw new Error("No post data found");
        }
        setPost(postData);

        const postDateUrl = `${process.env.REACT_APP_API_ROOT}/posts/${postData.id}?_fields=date`;
        const postDateResponse = await axios.get(postDateUrl);
        if (!postDateResponse.data) {
          throw new Error("No date data found");
        }
        setPostDates({ ...postDates, [postData.id]: postDateResponse.data.date });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (loading) {
    return (
      <div className="loading-overlay">
          <Bars
            height="100"
            width="100"
            color="#e91e63"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
    );
  }
  return (
    <>
    
    
      {Object.keys(post).length ? (
        <div className="containe mx-auto p-10 bg-white pt-20 lg:px-[200px]" style={{ marginBottom: 100}} style={{background:'white'}}>
          <div>
            
            {/*
            
            <Link to={()=>{navigate(-1)}} className="flex items-center">
              <UndoSharpIcon className="mr-2" style={{fontSize:28, fontWeight:'bolder'}} /> Revenir
            </Link>
             */}

            <button onClick={handleClick} className="flex items-center" style={{ color:'#e91e64',fontWeight:700}}>
            <UndoSharpIcon className="mr-2" style={{fontSize: 28, fontWeight: 'bolder'}} />
              Revenir
            </button>
            
            <h1 className="lg:text-5xl text-3xl font-bold mb-8" style={{lineHeight:1.2}}>
              {he.decode(post.title.rendered)}
            </h1>
          </div>
          <div className="mb-5">
            <img src={post.featured_src} alt={post.title.rendered} className="w-full h-auto rounded-lg" />
          </div>
          <div>
            <p className="text-gray-500 text-sm" style={{ marginBottom: 10, fontSize: 15 }}>
              Publié le : {new Date(postDates[post.id]).toLocaleDateString()}
            </p>
          </div>
          <div className="text-lg leading-relaxed" dangerouslySetInnerHTML={{ __html: post.content.rendered }} style={{marginBottom:64}}></div>          
        </div>
      ) : (
        <div className='col-span-3 w-4 h-4 absolute left-1/2 top-1/2 rounded-full border-4 border-b-0 border-blue-500 animate-spin' />
      )}
      <br/>
      <br/>
      <br/>
      <CustomBottomNavigation />
    </>
  );
};

export default SingleBySlug;
